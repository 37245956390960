

import React from "react";
import Slider from "react-slick";
import { Box, Typography, IconButton } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gallery_1_1 from '../img/gallery/gallery_1_1.jpg';
import gallery_1_2 from '../img/gallery/gallery_1_2.jpg';
import gallery_1_3 from '../img/gallery/gallery_1_3.jpg';
import gallery_1_4 from '../img/gallery/gallery_1_4.jpg';

function SimpleSlider3() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slides = [
    {
      imgSrc: gallery_1_1,
      subtitle: "IT Solution",
      title: "Cross-Industry Expertise",
    },
    {
      imgSrc: gallery_1_2,
      subtitle: "IT Solution",
      title: "Business Solution",
    },
    {
      imgSrc: gallery_1_3,
      subtitle: "IT Solution",
      title: "Service Consultation",
    },
    {
      imgSrc: gallery_1_4,
      subtitle: "IT Solution",
      title: "Website Security",
    },
  ];

  return (
    <Box sx={{ padding: "40px 20px", backgroundColor: "#f9f9f9" }}>
      <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography variant="h6" color="primary">
          Quick Projects
        </Typography>
        <Typography variant="h4" fontWeight="bold">
          Our Successful Projects
        </Typography>
      </Box>

      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Box
            key={index}
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "8px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Box
              component="img"
              src={slide.imgSrc}
              alt={slide.title}
              sx={{
                width: "100%",
                height: "200px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
            <Box sx={{ padding: "10px", textAlign: "center" }}>
              <Typography variant="subtitle2" color="textSecondary">
                {slide.subtitle}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                {slide.title}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}

export default SimpleSlider3;