import React from 'react'
import headerlogo from './assets/img/Scr.png';


export const Navbar = () => {
  return (
    <header className="ot-header header-layout1">
    <div className="header-top">
        <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                <div className="col-auto d-none d-lg-block">
                    <p className="header-notice">Mon-Fri: 8:00 AM - 6:30 PM</p>
                </div>
                <div className="col-auto">
                    <div className="header-links2">
                        <ul>
                            <li className="d-none d-sm-inline-block">
                                <div className="links-menu">
                                    <ul>
                                        <li><a href="/Service">All Services</a></li>
                                        <li><a href="about.html">Careers</a></li>
                                        <li><a href="about.html">News</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li><i className="far fa-envelope"></i><a
                                    href="Info@kavimaran.com">Info@kavimaran.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="sticky-wrapper">
        <div className="menu-area">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                        <div className="header-logo"><a href="index.html"><img src={headerlogo}
                                    alt="Tronix/"/></a></div>
                    </div>
                    <div className="col-auto">
                        <nav className="main-menu d-none d-lg-inline-block">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/About">About Us</a></li>
                                <li><a href="/Service">Service</a>
                                </li>
                                {/* <li><a href="">Blog</a>
                                </li> */}
                                <li><a href="/Contact">Contact</a></li>
                            </ul>
                        </nav><button type="button" className="ot-menu-toggle d-block d-lg-none"><i
                                className="far fa-bars"></i></button>
                    </div>
                    <div className="col-auto d-none d-xl-block">
                        <div className="header-button">
                            <div className="call-btn">
                                <div className="icon-btn"><i className="fas fa-phone"></i></div>
                                <div className="media-body"><span className="box-subtitle">Quick Call</span>
                                    <h4 className="box-title"><a href="tel:+9041236625">+91 7598984380</a></h4>
                                </div>
                            </div><a href="contact.html" className="ot-btn btn-sm">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
</header>
  )
}
