import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testi_bg_1 from '../img/bg/testi_bg_1.jpg';
import team_1_1 from '../img/team/team_1_1.jpg';
import team_1_2 from '../img/team/team_1_2.jpg';
import team_1_3 from '../img/team/team_1_3.jpg';
import team_1_4 from '../img/team/team_1_4.jpg';

function SimpleSlider2() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <>
       <section className="space">
            <div className="title-area text-center"><span className="sub-title"><span className="text">Team Members</span></span>
                <h2 className="sec-title">Our Top Skilled Experts</h2>
            </div>
            <div className="swiper ot-slider has-shadow" id="teamSlider1"
                data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'>
    <Box
    
      sx={{
        maxWidth: "1000px",
        margin: "0 auto",
        // padding: "20px",
        backgroundImage: "url()",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Slider {...settings}>
        {/* Slide 1 */}
        <Box
        
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_1} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Rocky Morgan</a></h3><span
                                    className="box-desig">CEO Founder</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>

        {/* Slide 2 */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
            
            <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: "10px" }}>
              On-Time Delivery
            </Typography>
            <Typography variant="body1">
              Never miss a deadline with our fast and reliable essay writing service.
            </Typography>
          </Box>
        </Box> */}

        {/* Slide 3 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_2} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Eliana Bellin</a></h3><span
                                    className="box-desig">Marketer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>

        {/* Slide 4 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_3} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Emanuel Macu</a></h3><span
                                    className="box-desig">Designer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>

        {/* Slide 5 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
            // background: "linear-gradient(to right, #3f51b5, #5c6bc0)",
            borderRadius: "8px",
            color: "#fff",
            textAlign: "center",
            padding: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
          <div className="swiper-slide">
                        <div className="ot-team team-card">
                            <div className="box-img"><img src={team_1_4} alt="Team"/></div>
                            <div className="box-content">
                                <h3 className="box-title"><a href="team-details.html">Donne Joanne</a></h3><span
                                    className="box-desig">Developer</span>
                                <div className="ot-social"><a target="_blank" href="https://facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a target="_blank"
                                        href="https://twitter.com/"><i className="fab fa-twitter"></i></a> <a
                                        target="_blank" href="https://instagram.com/"><i
                                            className="fab fa-instagram"></i></a> <a target="_blank"
                                        href="https://linkedin.com/"><i className="fab fa-linkedin-in"></i></a></div>
                            </div>
                        </div>
                    </div>
          </Box>
        </Box>
      </Slider>
    </Box>
    </div>
    </section>
    

    </>
  );
}

export default SimpleSlider2;