import React from "react"


import card1svg from './assets/img/icon/service_card_1.svg';
import card2svg from './assets/img/icon/service_card_2.svg';
import card3svg from './assets/img/icon/service_card_3.svg';
import card4svg from './assets/img/icon/service_card_4.svg';
import vector11png from './assets/img/normal/vector_1.png';
import blog11jpg from './assets/img/blog/blog_1_1.jpg';
import blog12jpg  from './assets/img/blog/blog_1_2.jpg';
import blog13jpg  from './assets/img/blog/blog_1_3.jpg';
import post1jpg  from './assets/img/blog/recent-post-1-1.jpg';
import post2jpg  from './assets/img/blog/recent-post-1-2.jpg';

export default function Service(){
    return <>
 {/* <div className="preloader"><button className="ot-btn preloaderCls style3">Cancel Preloader</button>
        <div className="preloader-inner"><span className="loader"></span></div>
    </div> */}
    <div className="ot-menu-wrapper">
        <div className="ot-menu-area text-center"><button className="ot-menu-toggle"><i className="fal fa-times"></i></button>
            <div className="mobile-logo"><a href="index.html"><img src="assets/img/logo.svg" alt="Tronix"/></a></div>
            <div className="ot-mobile-menu">
                <ul>
                    <li className="menu-item-has-children"><a href="index.html">Home</a>
                        <ul className="sub-menu">
                            <li><a href="index.html">Home One</a></li>
                            <li><a href="index-2.html">Home Two</a></li>
                            <li><a href="index-3.html">Home Three</a></li>
                        </ul>
                    </li>
                    <li><a href="about.html">About Us</a></li>
                    <li className="menu-item-has-children"><a href="#">Service</a>
                        <ul className="sub-menu">
                            <li><a href="service.html">Service</a></li>
                            <li><a href="service-details.html">Service Details</a></li>
                        </ul>
                    </li>
                    <li className="menu-item-has-children"><a href="#">Pages</a>
                        <ul className="sub-menu">
                            <li><a href="team.html">Team</a></li>
                            <li><a href="team-details.html">Team Details</a></li>
                            <li><a href="pricing.html">Pricing Plans</a></li>
                            <li><a href="project.html">Project Gallery</a></li>
                            <li><a href="project-details.html">Project Details</a></li>
                        </ul>
                    </li>
                    {/* <li className="menu-item-has-children"><a href="#">Blog</a>
                        <ul className="sub-menu">
                            <li><a href="blog.html">Blog</a></li>
                            <li><a href="blog-grid.html">Blog Grid Style</a></li>
                            <li><a href="blog-details.html">Blog Details</a></li>
                        </ul>
                    </li> */}
                    <li><a href="contact.html">Contact</a></li>
                </ul>
            </div>
        </div>
    </div>
    {/* <header className="ot-header header-layout1">
        <div className="header-top">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                    <div className="col-auto d-none d-lg-block">
                        <p className="header-notice">Mon-Fri: 8:00 AM - 6:30 PM</p>
                    </div>
                    <div className="col-auto">
                        <div className="header-links2">
                            <ul>
                                <li className="d-none d-sm-inline-block">
                                    <div className="links-menu">
                                        <ul>
                                            <li><a href="service.html">All Services</a></li>
                                            <li><a href="about.html">Careers</a></li>
                                            <li><a href="about.html">News</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li><i className="far fa-envelope"></i><a
                                        href="mailto:media@tronix.com">media@tronix.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky-wrapper">
            <div className="menu-area">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="header-logo"><a href="index.html"><img src="assets/img/logo.svg"
                                        alt="Tronix"/></a></div>
                        </div>
                        <div className="col-auto">
                            <nav className="main-menu d-none d-lg-inline-block">
                                <ul>
                                    <li className="menu-item-has-children"><a href="index.html">Home</a>
                                        <ul className="sub-menu">
                                            <li><a href="index.html">Home One</a></li>
                                            <li><a href="index-2.html">Home Two</a></li>
                                            <li><a href="index-3.html">Home Three</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="about.html">About Us</a></li>
                                    <li className="menu-item-has-children"><a href="#">Service</a>
                                        <ul className="sub-menu">
                                            <li><a href="service.html">Service</a></li>
                                            <li><a href="service-details.html">Service Details</a></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><a href="#">Pages</a>
                                        <ul className="sub-menu">
                                            <li><a href="team.html">Team</a></li>
                                            <li><a href="team-details.html">Team Details</a></li>
                                            <li><a href="pricing.html">Pricing Plans</a></li>
                                            <li><a href="project.html">Project Gallery</a></li>
                                            <li><a href="project-details.html">Project Details</a></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children"><a href="#">Blog</a>
                                        <ul className="sub-menu">
                                            <li><a href="blog.html">Blog</a></li>
                                            <li><a href="blog-grid.html">Blog Grid Style</a></li>
                                            <li><a href="blog-details.html">Blog Details</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="contact.html">Contact</a></li>
                                </ul>
                            </nav><button type="button" className="ot-menu-toggle d-block d-lg-none"><i
                                    className="far fa-bars"></i></button>
                        </div>
                        <div className="col-auto d-none d-xl-block">
                            <div className="header-button">
                                <div className="call-btn">
                                    <div className="icon-btn"><i className="fas fa-phone"></i></div>
                                    <div className="media-body"><span className="box-subtitle">Quick Call</span>
                                        <h4 className="box-title"><a href="tel:+9041236625">(904) 12-366-25</a></h4>
                                    </div>
                                </div><a href="contact.html" className="ot-btn btn-sm">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header> */}
    <div className="breadcumbbg">
    <div className="breadcumb-wrapper" data-bg-src="assets/img/bg/breadcumb-bg.jpg">
        <div className="container">
            <div className="breadcumb-content">
                <h1 className="breadcumb-title">Services</h1>
                <ul className="breadcumb-menu">
                    <li><a href="index.html">Home</a></li>
                    <li>Services</li>
                </ul>
            </div>
        </div>
    </div>
    </div>
    <section className="space" id="service-sec">
        <div className="container">
            <div className="row gy-4 justify-content-center">
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card1svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">Technology Solution</a></h3>
                        <p className="box-text">Risus turpis id mauris tellus ultricies cras nulla.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card1svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">IT Management Service</a></h3>
                        <p className="box-text">Developing a comprehense IT strategy that aligns.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card3svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">Website & Mobile App Design</a></h3>
                        <p className="box-text">Website and mobile apps design are critical.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card4svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">Data Tracking Security</a></h3>
                        <p className="box-text">Encryption is a method of converting sensitive.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card2svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">IT Management Service</a></h3>
                        <p className="box-text">Developing a comprehense IT strategy that aligns.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card3svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">Website & Mobile App Design</a></h3>
                        <p className="box-text">Website and mobile apps design are critical.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card4svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">Data Tracking Security</a></h3>
                        <p className="box-text">Encryption is a method of converting sensitive.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-3 col-md-6">
                    <div className="service-card">
                        <div className="box-icon"><img src={card1svg} alt="Icon"/></div>
                        <h3 className="box-title"><a href="service-details.html">Technology Solution</a></h3>
                        <p className="box-text">Risus turpis id mauris tellus ultricies cras nulla.</p><a
                            href="service-details.html" className="box-btn"><i className="fas fa-arrow-up-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="whybg3">
    <div className="overflow-hidden space" data-bg-src="assets/img/bg/why_bg_3.png">
        <div className="container pb-xxl-5">
            <div className="row align-items-center">
                <div className="col-lg-6 text-center text-lg-start">
                    <div className="pe-xxl-4">
                        <div className="title-area mb-37"><span className="sub-title text-white icon-white"><span
                                    className="text">Why Work With Us</span></span>
                            <h2 className="sec-title text-white">World’s No.1 Technology Solution Provider.</h2>
                            <p className="sec-text text-white">The goal of IT services is to provide efficient and effective
                                technology solutions that help businesses achieve their objectives.</p>
                        </div><a href="https://www.youtube.com/watch?v=_sI_Ps7JSEk" className="watch-btn popup-video">
                            <div className="play-btn style2"><i className="fas fa-play"></i></div><span
                                className="text text-white">Video Showcase</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="img-box5"><img src={vector11png} alt="vector"/></div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <section className="space-extra-top space" id="blog-sec">
        <div className="container">
            <div className="title-area text-center"><span className="sub-title"><span className="text">Blog Updates</span></span>
                <h2 className="sec-title">Latest News Posts</h2>
            </div>
            <div className="row gy-4 justify-content-center">
                <div className="col-xl-4 col-md-6">
                    <div className="blog-card">
                        <div className="blog-img"><img src={blog11jpg} alt="blog image"/></div>
                        <div className="blog-meta"><a href="blog.html"><i className="far fa-calendar"></i>15 Mar, 2023</a> <a
                                href="blog.html"><i className="far fa-user"></i>By Tronix</a></div>
                        <h3 className="box-title"><a href="blog-details.html">Top 5 IT Solutions for Small Businesses
                                Startup</a></h3>
                        <p className="box-text">Technology solutions that small businesses can implement to streamline their
                            operations and improve productivity.</p><a href="blog-details.html" className="link-btn">Read
                            Details<i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="blog-card">
                        <div className="blog-img"><img src={blog12jpg} alt="blog image"/></div>
                        <div className="blog-meta"><a href="blog.html"><i className="far fa-calendar"></i>16 Mar, 2023</a> <a
                                href="blog.html"><i className="far fa-user"></i>By Tronix</a></div>
                        <h3 className="box-title"><a href="blog-details.html">This post could discuss the top technology
                                solutions</a></h3>
                        <p className="box-text">Technology solutions that small businesses can implement to streamline their
                            operations and improve productivity.</p><a href="blog-details.html" className="link-btn">Read
                            Details<i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="blog-card">
                        <div className="blog-img"><img src={blog13jpg} alt="blog image"/></div>
                        <div className="blog-meta"><a href="blog.html"><i className="far fa-calendar"></i>17 Mar, 2023</a> <a
                                href="blog.html"><i className="far fa-user"></i>By Tronix</a></div>
                        <h3 className="box-title"><a href="blog-details.html">How to Choose the Right IT Solution
                                Provider</a></h3>
                        <p className="box-text">Technology solutions that small businesses can implement to streamline their
                            operations and improve productivity.</p><a href="blog-details.html" className="link-btn">Read
                            Details<i className="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="footerservive">
    <footer className="footer-wrapper footer-layout1" data-bg-src="assets/img/bg/footer_bg_1.jpg">
        <div className="widget-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">About Us</h3>
                            <div className="ot-widget-about">
                                <p className="footer-text">An IT consultancy can help you assess your technology needs and
                                    develop a technology strategy that aligns with your business</p>
                                <div className="ot-social"><a href="https://www.facebook.com/"><i
                                            className="fab fa-facebook-f"></i></a> <a href="https://www.twitter.com/"><i
                                            className="fab fa-twitter"></i></a> <a href="https://www.linkedin.com/"><i
                                            className="fab fa-linkedin-in"></i></a> <a href="https://www.whatsapp.com/"><i
                                            className="fab fa-whatsapp"></i></a> <a href="https://www.youtube.com/"><i
                                            className="fab fa-youtube"></i></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget widget_nav_menu footer-widget">
                            <h3 className="widget_title">Links</h3>
                            <div className="menu-all-pages-container">
                                <ul className="menu">
                                    <li><a href="about.html">About Us</a></li>
                                    <li><a href="about.html">Our Mission</a></li>
                                    <li><a href="team.html">Meet The Teams</a></li>
                                    <li><a href="project.html">Our Projects</a></li>
                                    <li><a href="contact.html">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget widget_nav_menu footer-widget">
                            <h3 className="widget_title">Explore</h3>
                            <div className="menu-all-pages-container">
                                <ul className="menu">
                                    <li><a href="service.html">What We Offer</a></li>
                                    <li><a href="about.html">Our Story</a></li>
                                    <li><a href="blog.html">Latest Posts</a></li>
                                    <li><a href="about.html">Help Center</a></li>
                                    <li><a href="contact.html">Get Support</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-auto">
                        <div className="widget footer-widget">
                            <h3 className="widget_title">Recent Posts</h3>
                            <div className="recent-post-wrap">
                                <div className="recent-post">
                                    <div className="media-img"><a href="blog-details.html"><img
                                                src={post1jpg} alt="Blog Image"/></a></div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Building
                                                Renovation Tasks</a></h4>
                                        <div className="recent-post-meta"><a href="blog.html"><i
                                                    className="far fa-calendar"></i>21 Jun, 2023</a></div>
                                    </div>
                                </div>
                                <div className="recent-post">
                                    <div className="media-img"><a href="blog-details.html"><img
                                                src={post2jpg} alt="Blog Image"/></a></div>
                                    <div className="media-body">
                                        <h4 className="post-title"><a className="text-inherit" href="blog-details.html">Get
                                                Started With Our Team</a></h4>
                                        <div className="recent-post-meta"><a href="blog.html"><i
                                                    className="far fa-calendar"></i>22 Jun, 2023</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="footer-contact-wrap">
                    <div className="footer-contact">
                        <div className="box-icon icon-btn"><i className="fal fa-location-dot"></i></div>
                        <div className="media-body">
                            <h4 className="box-title">Location</h4>
                            <p className="box-text">258 Daniel Mansion 258 Berlin Germany</p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="footer-contact">
                        <div className="box-icon icon-btn"><i className="fal fa-clock"></i></div>
                        <div className="media-body">
                            <h4 className="box-title">Working Hours</h4>
                            <p className="box-text"><span>Weekdays 8am - 22pm</span><br/><span>Weekend 10am - 12pm</span></p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="footer-contact">
                        <div className="box-icon icon-btn"><i className="fa-light fa-phone-volume"></i></div>
                        <div className="media-body">
                            <h4 className="box-title">Contact Us</h4>
                            <p className="box-text"><span>info@tronix.com</span><br/><span>(+259) 2257 6156</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrap">
            <div className="container text-center">
                <p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2023 <a
                        href="index.html">Tronix</a>. All Rights Reserved.</p>
            </div>
        </div>
    </footer>
    </div>
    <div className="scroll-top"><svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
               style={{
                transition: "stroke-dashoffset 10ms linear 0s",
                strokeDasharray: "307.919, 307.919",
                strokeDashoffset: 307.919
              }}
              >
                  </path>
        </svg></div>
    <script src="assets/js/vendor/jquery-3.7.1.min.js"></script>
    <script src="assets/js/app.min.js"></script>
    <script src="assets/js/main.js"></script>


    </>}

   